import type { StateDeclarationFn } from 'controller/types';

import { GroupAppKey } from 'store/groups/types';
import { selectIsAppAvailable } from 'store/selectors';

export default (function (vm, store, params) {
  return [
    {
      abstract: true,
      name: 'group.events',
      url: '/events',
      data: {
        sectionId: 'group',
        title: 'groups-web.events',
        application: GroupAppKey.EVENTS_APP,
      },
    },
    {
      name: 'group.events.list',
      url: ':eventId?',
      params: {
        eventId: {
          value: null,
          squash: true,
        },
      },
      resolve: [
        {
          token: 'eventsAvailable',
          deps: ['groupId'],
          resolveFn(groupId: string) {
            return selectIsAppAvailable(store.getState(), {
              groupId,
              application: GroupAppKey.EVENTS_APP,
            });
          },
        },
        {
          token: 'events',
          deps: ['groupId', 'eventsAvailable'],
          resolveFn(groupId: string, isEventsAvailable: boolean) {
            if (isEventsAvailable) {
              return vm.events$.fetch(groupId);
            }
          },
        },
      ],
    },
    {
      name: 'group.events.create',
      url: '/create',
    },
  ];
} as StateDeclarationFn);
